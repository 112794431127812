<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Purchase Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Supplier Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Purchase Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 mb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            class="mg-t-5 mr-2"
            v-model="supplierWiseParams.pageIndex"
            @change="getFilteredDataWithoutDates"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select class="mg-t-5 mr-2" v-model="session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>

          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="supplierWiseParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="supplierWiseParams.to_date"
              placeholder="To"
              class="mg-t-5 filter-input"
              @change="selectEndDate"
            />
          </div>
          <input
            type="text"
            v-model="supplierWiseParams.searched"
            @keyup="searchKeyword"
            class="filter-input mg-t-5 mr-2"
            placeholder="Search..."
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/purchase">Bills Wise</option>
              <option value="/general-report/purchase/branch-wise">
                Branch Wise
              </option>
              <option value="/general-report/purchase/day-wise">
                Day Wise
              </option>
              <option value="/general-report/purchase/month-wise">
                Month Wise
              </option>
              <option value="/general-report/purchase/supplier-wise">
                Supplier Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Purchase: NPR
          {{ parseDigitForSlip(supplierWiseAttributes.total_purchase) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Credit: NPR
          {{ parseDigitForSlip(supplierWiseAttributes.total_due) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Bills Count: NPR
          {{ parseDigitForSlip(supplierWiseAttributes.total_bills) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Import: NPR
          {{
            parseDigitForSlip(supplierWiseAttributes.total_import_purchase)
          }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Local Purchase: NPR
          {{
            parseDigitForSlip(supplierWiseAttributes.total_local_purchase)
          }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT able Purchase: NPR
          {{
            parseDigitForSlip(supplierWiseAttributes.total_vatable_amount)
          }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT: NPR
          {{ parseDigitForSlip(supplierWiseAttributes.total_vat_amount) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Order: NPR
          {{
            parseDigitForSlip(supplierWiseAttributes.total_purchase_orders)
          }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Supplier ID</th>
              <th class="wd-20p">Supplier Name</th>
              <th class="wd-15p">Address</th>
              <th class="wd-10p">Total Purchase</th>
              <th class="wd-10p">Bill Amt.</th>
              <th class="wd-10p">VAT-able Amt.</th>
              <th class="wd-10p">VAT</th>
              <th class="wd-10p">Due</th>
            </tr>
          </thead>
          <tbody v-if="!loading && supplierWiseDatas.length > 0">
            <tr v-for="(purchase, index) in supplierWiseDatas" :key="index">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ purchase.supplier_id }}</td>
              <td>{{ purchase.supplier_name }}</td>
              <td>{{ purchase.supplier_address }}</td>
              <td>
                {{ parseDigitForSlip(purchase.total_purchase_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_bill_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_vatable_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_vat_amount) }}
              </td>
              <td>
                {{ parseDigitForSlip(purchase.total_customer_due) }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>

          <tbody v-else-if="!loading && supplierWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import _ from "lodash";

export default {
  components: {
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: ["2021"],
      session_year: "2021",
      route: this.$route.path,
      branches: [],
      supplierWiseParams: {
        pageIndex: 10,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
      errorMessage: "",
      loading: false,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
      month: new Date().getMonth(),
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("purchaseReports", [
      "supplierWiseDatas",
      "supplierWiseAttributes",
    ]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;

    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    filterReportType() {
      if (this.$route.path != this.route) {
        this.$router.push(this.route);
      }
    },
    print(){
      window.print();
    },
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.supplierWiseParams.from_date = "";
        this.supplierWiseParams.to_date = "";
        this.supplierWiseParams.day = value.day;
        this.supplierWiseParams.month = value.month;
        this.supplierWiseParams.year = value.year;
      }
      this.page = 1;
      this.pageSerialNo = 1;
      this.supplierWiseParams.offset = 0;
      //make api call
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.supplierWiseParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.supplierWiseParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.supplierWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.supplierWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.supplierWiseParams.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 1000),
    getFilteredData() {
      this.loading = true;

      let params = {
        pageIndex: this.supplierWiseParams.pageIndex,
        branch: this.supplierWiseParams.branch,
        today: this.supplierWiseParams.day,
        month: this.supplierWiseParams.month + 1,
        year: this.supplierWiseParams.year,
        from_date: this.supplierWiseParams.from_date,
        to_date: this.supplierWiseParams.to_date,
        searched: this.supplierWiseParams.searched,
        page_reload: this.pageReload,
        offset: this.supplierWiseParams.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.supplierWiseParams.pageIndex,
        branch: this.supplierWiseParams.branch,
        today: "",
        month: "",
        year: this.supplierWiseParams.year,
        from_date: this.supplierWiseParams.from_date,
        to_date: this.supplierWiseParams.to_date,
        searched: this.supplierWiseParams.searched,
        page_reload: this.pageReload,
        offset: this.supplierWiseParams.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.error = false;
      Services.getSupplierWiseFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "purchaseReports/setSupplierWiseAttributes",
            res.data.data.attributes
          );
          this.$store.commit(
            "purchaseReports/setSupplierWiseDatas",
            res.data.data.data
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
     download(){
      Services.getSupplierWiseExcel(this.supplierWiseParams).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'purchase-supplier-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>